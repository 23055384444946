import React from "react"

export default function ContactUs() {
  return (
    <div className="container pb-5 pt-5 mt-5">
      <h4>Contact Us</h4>
      <div className="row gap-4">
        <div className="col-sm">
          <div>
            <strong>E-mail:</strong> contact@indianesgnetwork.com
          </div>
          <div>
            <strong>Website: </strong>
            <a href="https://www.indianesgnetwork.com/" target="_blank">
              https://www.indianesgnetwork.com/
            </a>
          </div>
          <div>
            <strong>Landline: </strong> 011-41073077
          </div>
          <div>
            <strong> Phone: </strong> +91 9812345667
          </div>
          <div>
            <strong>Address: </strong> B-100, LGF, Sarvodya Enclave, New Delhi
            110017, India
          </div>
        </div>
        <div className="col-sm">
          <h5>
            To collaborate, contact us at: <br />
          </h5>
          Indian ESG Network Secretariat:
          <br /> Ms. Aayushi Jauhari, Lead - Indian ESG Secretariat
        </div>
      </div>
    </div>
  )
}
